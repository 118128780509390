'use client'

import { createContext } from 'react'

import type { Screen } from 'constants/tracking/screens'

import EventTracker from '../utils/client-event-tracker'

type TrackingContextValue = {
  tracker: EventTracker
  screen?: Screen
}

const TrackingContext = createContext<TrackingContextValue | undefined>(undefined)

export default TrackingContext
