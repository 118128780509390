function pickBy<T extends object, K extends keyof T>(
  object: T,
  predicate: (value: T[K], key: K) => boolean,
): Partial<T> {
  const picked: Partial<T> = { ...object }

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object) as Array<[K, T[K]]>) {
    if (!predicate(value, key)) {
      delete picked[key]
    }
  }

  return picked
}

export const filterEmptyValues = <T extends object>(object: T): Partial<T> =>
  pickBy(
    object,
    val =>
      !(
        (Array.isArray(val) && (val as Array<unknown>).length === 0) ||
        val === null ||
        typeof val === 'undefined'
      ),
  )

export const isValueInObject = <T extends string | number>(
  value: unknown,
  object: Record<string, T>,
): value is T => Object.values(object).includes(value as T)

export const isObject = (candidate: unknown): candidate is Record<string, unknown> =>
  !!candidate && typeof candidate === 'object'

// TODO: remove after finish app dir migration
export const deleteUndefinedValues = (object: Record<string, unknown> | undefined): void => {
  if (!object) return

  // eslint-disable-next-line no-restricted-syntax
  for (const key in object) {
    if (!Object.hasOwn(object, key)) continue // eslint-disable-line no-continue

    const value = object[key]

    if (isObject(value)) deleteUndefinedValues(value)
    else if (value === undefined) delete object[key] // eslint-disable-line no-param-reassign
  }
}
