export enum SortByOption {
  Relevance = 'relevance',
  PriceHighToLow = 'price_high_to_low',
  PriceLowToHigh = 'price_low_to_high',
  NewestFirst = 'newest_first',
}

export const SELECT_DROPDOWN_MAX_HEIGHT = 320
export const SELECT_DROPDOWN_MIN_WIDTH = 300
export const GRID_FILTER_DROPDOWN_MIN_WIDTH = 328
export const GRID_FILTER_DROPDOWN_MAX_HEIGHT = 388
