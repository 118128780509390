export enum Hostname {
  At = 'vinted.at',
  AtSandbox = 'sandbox-at.vinted.net',
  Be = 'vinted.be',
  BeSandbox = 'sandbox-be.vinted.net',
  Cz = 'vinted.cz',
  CzSandbox = 'sandbox-cz.vinted.net',
  De = 'vinted.de',
  DeSandbox = 'sandbox-de.vinted.net',
  Dk = 'vinted.dk',
  DkSandbox = 'sandbox-dk.vinted.net',
  Es = 'vinted.es',
  EsSandbox = 'sandbox-es.vinted.net',
  Fi = 'vinted.fi',
  FiSandbox = 'sandbox-fi.vinted.net',
  Fr = 'vinted.fr',
  FrSandbox = 'sandbox-fr.vinted.net',
  Gr = 'vinted.gr',
  GrSandbox = 'sandbox-gr.vinted.net',
  Hr = 'vinted.hr',
  HrSandbox = 'sandbox-hr.vinted.net',
  Hu = 'vinted.hu',
  HuSandbox = 'sandbox-hu.vinted.net',
  Ie = 'vinted.ie',
  IeSandbox = 'sandbox-ie.vinted.net',
  It = 'vinted.it',
  ItSandbox = 'sandbox-it.vinted.net',
  Lt = 'vinted.lt',
  LtSandbox = 'sandbox-new-lt.vinted.net',
  Lu = 'vinted.lu',
  LuSandbox = 'sandbox-lu.vinted.net',
  Nl = 'vinted.nl',
  NlSandbox = 'sandbox-nl.vinted.net',
  Pl = 'vinted.pl',
  PlSandbox = 'sandbox-pl.vinted.net',
  Pt = 'vinted.pt',
  PtSandbox = 'sandbox-pt.vinted.net',
  Ro = 'vinted.ro',
  RoSandbox = 'sandbox-ro.vinted.net',
  Se = 'vinted.se',
  SeSandbox = 'sandbox-se.vinted.net',
  Sk = 'vinted.sk',
  SkSandbox = 'sandbox-sk.vinted.net',
  Uk = 'vinted.co.uk',
  UkSandbox = 'sandbox-uk.vinted.net',
  Us = 'vinted.com',
  UsSandbox = 'sandbox-us.vinted.net',
}

type SandboxHostname = (typeof Hostname)[keyof typeof Hostname & `${string}Sandbox`]
type ProductionHostname = Exclude<(typeof Hostname)[keyof typeof Hostname], SandboxHostname>

export const SANDBOX_HOSTNAME = Object.keys(Hostname).reduce((accumulator, key) => {
  if (key.endsWith('Sandbox')) accumulator.push(Hostname[key])

  return accumulator
}, [] as Array<SandboxHostname>)

export const DOMAIN_ALIASES: {
  sandbox: Record<SandboxHostname, string>
  production: Record<`www.${ProductionHostname}`, string>
} = {
  sandbox: {
    [Hostname.FrSandbox]: 'fr-FR',
    [Hostname.EsSandbox]: 'es-ES',
    [Hostname.BeSandbox]: 'fr-BE',
    [Hostname.LuSandbox]: 'fr-LU',
    [Hostname.NlSandbox]: 'nl-NL',
    [Hostname.LtSandbox]: 'lt-LT',
    [Hostname.DeSandbox]: 'de-DE',
    [Hostname.AtSandbox]: 'de-AT',
    [Hostname.ItSandbox]: 'it-IT',
    [Hostname.UkSandbox]: 'en-GB',
    [Hostname.PtSandbox]: 'pt-PT',
    [Hostname.UsSandbox]: 'en-US',
    [Hostname.CzSandbox]: 'cs-CZ',
    [Hostname.SkSandbox]: 'sk-SK',
    [Hostname.PlSandbox]: 'pl-PL',
    [Hostname.SeSandbox]: 'sv-SE',
    [Hostname.HuSandbox]: 'hu-HU',
    [Hostname.RoSandbox]: 'ro-RO',
    [Hostname.DkSandbox]: 'da-DK',
    [Hostname.FiSandbox]: 'fi-FI',
    [Hostname.HrSandbox]: 'hr-HR',
    [Hostname.GrSandbox]: 'gr-GR',
    [Hostname.IeSandbox]: 'en-IE',
  },
  production: {
    [`www.${Hostname.Fr}`]: 'fr-FR',
    [`www.${Hostname.Es}`]: 'es-ES',
    [`www.${Hostname.Be}`]: 'fr-BE',
    [`www.${Hostname.Lu}`]: 'fr-LU',
    [`www.${Hostname.Nl}`]: 'nl-NL',
    [`www.${Hostname.Lt}`]: 'lt-LT',
    [`www.${Hostname.De}`]: 'de-DE',
    [`www.${Hostname.At}`]: 'de-AT',
    [`www.${Hostname.It}`]: 'it-IT',
    [`www.${Hostname.Uk}`]: 'en-GB',
    [`www.${Hostname.Pt}`]: 'pt-PT',
    [`www.${Hostname.Us}`]: 'en-US',
    [`www.${Hostname.Cz}`]: 'cs-CZ',
    [`www.${Hostname.Sk}`]: 'sk-SK',
    [`www.${Hostname.Pl}`]: 'pl-PL',
    [`www.${Hostname.Se}`]: 'sv-SE',
    [`www.${Hostname.Hu}`]: 'hu-HU',
    [`www.${Hostname.Ro}`]: 'ro-RO',
    [`www.${Hostname.Dk}`]: 'da-DK',
    [`www.${Hostname.Fi}`]: 'fi-FI',
    [`www.${Hostname.Hr}`]: 'hr-HR',
    [`www.${Hostname.Gr}`]: 'gr-GR',
    [`www.${Hostname.Ie}`]: 'en-IE',
  },
}
