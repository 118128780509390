export enum CreditCardType {
  Visa = 'visa',
  Mastercard = 'mastercard',
  DinersClub = 'diners_club',
  AmericanExpress = 'american_express',
  Discover = 'discover',
  JCB = 'jcb',
  BCMC = 'bcmc',
  Maestro = 'maestro',
  CartesBancaires = 'cartes_bancaires',
  Unknown = 'unknown',
}

export enum DisplayedCardBrandName {
  JCB = 'JCB',
  Visa = 'Visa',

  // BCMC has to be displayed as Mastercard
  BCMC = 'Mastercard',
  Mastercard = 'Mastercard',

  DinersClub = 'Diners Club',
  AmericanExpress = 'American Express',
  CartesBancaires = 'Cartes Bancaires',

  Unknown = 'Unknown',
}

export enum CreditCardRequiredField {
  PostalCode = 'postal_code',
}

export enum CreditCardAddReturnResult {
  Success = 'success',
  Fail = 'fail',
}

export enum CreditCardAddSourceEntityType {
  Unknown = 'unknown',
  Transaction = 'transaction',
  SingleCheckout = 'single_checkout',
  PaymentSettings = 'payment_settings',
  NewPushUpOrder = 'new_push_up_order',
  ItemPushUpOrder = 'item_push_up_order',
  ProfilePushUpOrder = 'profile_push_up_order',
  MainClosetPromotion = 'main_closet_promotion',
  ProfileClosetPromotion = 'profile_closet_promotion',
  DirectDonationOrder = 'direct_donation_order',
  WebviewDirectDonationOrder = 'webview_direct_donation_order',
  MsgThreadReturnLabelOrder = 'msg_thread_return_label_order',
  ComplaintReturnLabelOrder = 'complaint_return_label_order',
}

export enum CreditCardFormLocation {
  Checkout = 'checkout',
  Settings = 'settings',
}
