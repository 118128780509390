import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { Pluralize, TranslateWithPluralize } from '../types/intl'
import { createTranslation } from '../utils/translations'

function useTranslate(prefix?: string) {
  const intl = useIntl()

  return useCallback<TranslateWithPluralize>(
    (suffix: string, values, pluralize?: Pluralize) => {
      return createTranslation(intl, prefix)(suffix, values, pluralize)
    },
    [intl, prefix],
  )
}

export default useTranslate
