// TODO: should push backend to return standardized ISO format
export const VINTED_LOCALE_TO_ISO_MAP = {
  cz: 'cs',
  'cz-fr': 'cs',
  'da-fr': 'da-DK',
  de: 'de',
  'de-fr': 'de',
  'el-fr': 'el-GR',
  en: 'en',
  'en-ca-fr': 'en-CA',
  'en-dev': 'en',
  'en-fr': 'en',
  'en-uk': 'en-GB',
  'en-uk-fr': 'en-GB',
  'en-us': 'en-US',
  'en-us-babies': 'en-US',
  'en-us-fr': 'en-US',
  es: 'es',
  'es-fr': 'es',
  'es-us-fr': 'es-US',
  'fi-fr': 'fi-FI',
  fr: 'fr',
  'fr-ca-fr': 'fr-CA',
  'hr-fr': 'hr-HR',
  'hu-fr': 'hu',
  it: 'it',
  'it-fr': 'it',
  lt: 'lt',
  'lt-fr': 'lt',
  nl: 'nl',
  'nl-fr': 'nl',
  pl: 'pl',
  'pl-fr': 'pl',
  'pt-fr': 'pt',
  'ro-fr': 'ro',
  'sk-fr': 'sk',
  'sv-fr': 'sv',
  keys: 'en',
}

/* Note: based on ISO 639-1 standard (https://vinted.atlassian.net/wiki/spaces/SWIT/pages/29537107978/ISO+Standards+for+Localization+V2#1.-Language-Standards%3A-ISO-639-1) * */
export enum ISO_LANGUAGE_CODES {
  en = 'en',
  fr = 'fr',
  de = 'de',
  nl = 'nl',
  es = 'es',
  pt = 'pt',
  it = 'it',
  cs = 'cs',
  pl = 'pl',
  sk = 'sk',
  lt = 'lt',
  se = 'se',
  dk = 'dk',
  fi = 'fi',
  hu = 'hu',
  ro = 'ro',
  hr = 'hr',
  el = 'el',
}

export const DEFAULT_ISO_LANGUAGE_CODE = 'en'
export const DEFAULT_LOCALE = 'en-fr'
