import { createElement, HTMLElementType } from 'react'

import { ISO_LANGUAGE_CODES } from '../constants/language'

const WHITELISTED_HTML_TAGS: Array<HTMLElementType> = [
  'b',
  'div',
  'i',
  'li',
  'ol',
  'p',
  'span',
  'strong',
  'ul',
  'u',
]

export const defaultInterpolations = WHITELISTED_HTML_TAGS.reduce(
  (accumulator, tag) => {
    accumulator[tag] = (chunks: JSX.Element) => createElement(tag, null, chunks)

    return accumulator
  },
  {} as Record<HTMLElementType, (chunks: JSX.Element) => ReturnType<typeof createElement>>,
)

/**
 * NOTES:
 * Pluralization Rule source of truth is written here https://docs.google.com/document/d/18nvEFey6tZ5vfjdGnQ3Ywpv3geazW0TKueMqKtv-Dgk/edit?tab=t.0"
 */

export enum PluralizeSuffix {
  Zero = 'zero',
  One = 'one',
  Few = 'few',
  Other = 'other',
}

const { One, Few, Other } = PluralizeSuffix

type Pluralize = (count: number) => string

const ltPluralize: Pluralize = count => {
  if (count % 10 === 1 && count % 100 !== 11) return One

  return [2, 3, 4, 5, 6, 7, 8, 9].includes(count % 10) &&
    ![11, 12, 13, 14, 15, 16, 17, 18, 19].includes(count % 100)
    ? Few
    : Other
}

const czPluralize: Pluralize = count => {
  if (count === 1) return One

  return [2, 3, 4].includes(count) ? Few : Other
}

const plPluralize: Pluralize = count => {
  if (count % 10 === 1 && count % 100 !== 11) return One

  return [2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100) ? Few : Other
}

const roPluralize: Pluralize = count => {
  if (count === 1) return One

  return count >= 20 ? Other : Few
}

const huPluralize: Pluralize = () => One

const frPluralize: Pluralize = count => (count <= 1 ? One : Other)

const skPluralize: Pluralize = count => {
  if (count === 1) return One

  return [2, 3, 4].includes(count) ? 'few' : 'other'
}

const hrPluralize: Pluralize = count => {
  if (count === 1) return One

  return [2, 3, 4].includes(count) ? Few : Other
}

const defaultPluralize: Pluralize = count => (count === 1 ? One : Other)

const { lt, cs, pl, ro, hu, fr, sk, hr } = ISO_LANGUAGE_CODES

const pluralFunctionMap: Record<string, Pluralize> = {
  [lt]: ltPluralize,
  [cs]: czPluralize,
  [pl]: plPluralize,
  [ro]: roPluralize,
  [hu]: huPluralize,
  [fr]: frPluralize,
  [sk]: skPluralize,
  [hr]: hrPluralize,
}

export const pluralization = (locale: string) => {
  const pluralizationFunction = pluralFunctionMap[locale]

  return pluralizationFunction ?? defaultPluralize
}
