import { MS_PER_SECOND } from '@marketplace-web/shared/utils'

export enum ExtraServiceOrderType {
  PushUp = 'push_up_order',
  ClosetPromotion = 'closet_promotion_order',
  DirectDonation = 'direct_donation_order',
  ReturnLabel = 'return_label_order',
  Transaction = 'transaction',
}

export enum CheckoutOrderType {
  PushUp = 'push_up',
  ClosetPromotion = 'closet_promotion',
  DirectDonation = 'direct_donation',
  ReturnLabel = 'return_label',
  Transaction = 'transaction',
}

export const CheckoutOrderTypeMap = {
  [ExtraServiceOrderType.PushUp]: CheckoutOrderType.PushUp,
  [ExtraServiceOrderType.ClosetPromotion]: CheckoutOrderType.ClosetPromotion,
  [ExtraServiceOrderType.DirectDonation]: CheckoutOrderType.DirectDonation,
  [ExtraServiceOrderType.ReturnLabel]: CheckoutOrderType.ReturnLabel,
  [ExtraServiceOrderType.Transaction]: CheckoutOrderType.Transaction,
}

export const PAYMENT_POLL_DELAY = MS_PER_SECOND

export enum ExtraServiceCheckoutModal {
  PreCheckout = 'preCheckout',
  PreCheckoutHelp = 'preCheckoutHelp',
  DynamicPricingInfo = 'dynamicPricingInfo',
  OrderReview = 'orderReview',
  ValueProposition = 'valueProposition',
  PayInMethodSelection = 'payInMethodSelection',
  PaymentConfirmation = 'paymentConfirmation',
  OrderConfirmation = 'orderConfirmation',
  PaymentAuth = 'paymentAuth',
  CreditCardForm = 'creditCardForm',
  BlikCodeInput = 'blikCodeInput',
  SalesTaxInfo = 'salesTaxInfo',
  OrderStatus = 'orderStatus',
  Checkout = 'checkout',
  Error = 'error',
  GenericErrorModal = 'genericErrorModal',
  None = 'none',
}

export enum ExtraServiceOrderStatus {
  Success = 'success',
  Processing = 'processing',
  // LongProcessing = 'long_processing',
  Failure = 'failure',
  Idle = 'idle',
}
