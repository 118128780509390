import { noop } from 'lodash'

import { serverSide } from '@marketplace-web/shared/environment'

type RequireOnlyReturnedValues<T> = Partial<T> &
  Omit<T, KeysOfType<T, (...args: any) => void | undefined>>

const proxyHandler = <T extends Record<string, (...args: ReadonlyArray<any>) => unknown>>(
  handledMethods: RequireOnlyReturnedValues<T>,
): ProxyHandler<T> => ({
  get: (target, prop) => handledMethods[prop.toString()] || noop,
})

export const withServerSideProxy = <
  T extends Record<string, (...args: ReadonlyArray<any>) => unknown>,
>(
  target: T,
  handler: RequireOnlyReturnedValues<T>,
) => (serverSide ? new Proxy(target, proxyHandler<T>(handler)) : target)
