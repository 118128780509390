import { withServerSideProxy } from '@marketplace-web/shared/ssr'

export const { navigateToPage, redirectToPage, reloadPage } = withServerSideProxy(
  {
    navigateToPage: (path: string) => {
      window.location.href = path
    },
    redirectToPage: (page: string) => window.location.replace(page),
    redirectToPageInNewTab: (page: string) => {
      window.open(page, '_blank')
    },
    reloadPage: (): void => window.location.reload(),
  },
  {},
)
